.container {
    display: flex;
    justify-content: center;
    margin: 10px 50px;
}

.avatar {
    margin: 15px 40px;
    width: 70px;
    align-self: flex-start;
}

.information {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    margin: 0 10px 50px 10px;
}

.viewMode {
    display: flex;
    max-width: 300px;
    justify-content: space-between;
}

.personalInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 65px;
}

.personalInfoItem {
    margin: 5px 0;
}

.clinicsSection {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    margin: 30px 0;
}

.clinicsList {
    margin: 20px 0;
}

.clinicItem {
    display: flex;
    justify-content: space-between;
}

.clinicName {
    width: 100%;
    padding: 7.5px 0;
    margin: 5.5px 0 6.5px 0;
}

.editForm {
    margin: 0 10px 50px 10px;
    width: 100%;
}

.formElement {
    display: flex;
    justify-content: space-between;
}

label {
    margin: 10px;
}

input, input:focus {
    border: none;
    outline: none;
}

.inputField, .inputField:focus {
    margin: 10px 30px;
    background-color: var(--grey-1);
    border-bottom: 1px solid var(--grey-5);
}

.buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.addClinicField {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;
}

.editClinicField {
    display: flex;
    justify-content: space-between;
}

.addClinicInput,
.addClinicInput:focus {
    background-color: var(--grey-1);
    border: 1px solid var(--grey-5);
    caret-color: var(--grey-5);
    color: var(--grey-5);
}

.addClinicInput::placeholder {
    color: var(--grey-4);
}

.editClinicInput,
.editClinicInput:focus {
    width: 220px;
    background-color: var(--grey-1);
    border-bottom: 1px solid var(--grey-5);
    font-family: Inter;
    font-size: 16px;
    margin: 6px 0;
}