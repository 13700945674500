* {
  padding: 0;
  margin: 0;
  color: var(--grey-5);
}

@font-face {
  font-family: 'Inter';
  src: local('Inter'), url('./styles/fonts/Inter/Inter-VariableFont_slnt\,wght.ttf') format("truetype");
}


:root {
  --grey-1: #272727;
  --grey-2: #313131;
  --grey-3: #484848;
  --grey-4: #787878;
  --grey-5: #cbcbcb;
  --grey-6: #ADB5BD;

  --red: #ff8383;
}

html {
  display: flex;
  flex-direction: column;
  height: 70vh;
  width: 100vw;
  background: var(--grey-1);
  font-family: Inter;
  font-size: 16px;
}


.editButton,
.deleteButton,
.plusButton,
.closeButton,
.tickButton {
  width: 20px;
  height: 25px;
  cursor: pointer;
  margin: 10px;
}

button {
  all: unset;
}

.simpleButton {
  background-color: var(--grey-2);
  border: 1px solid var(--grey-5);
}

.simpleButton:hover {
  background-color: var(--grey-3);
  border: 1px solid var(--grey-5);
}

.alertMessage {
  color: var(--red);
  font-size: 14px;
  width: 100%;
  margin: 2px;
}

.hiddenAlertMessage {
  display: none;
}

.mainHeader {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  font-size: 32px;
}

.subHeader {
  font-size: 24px;
  margin: 10px 0;
}