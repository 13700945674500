.addPatientFormContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
}

.addPatientFormFields {
    display: flex;
    margin: 30px 80px;
}

.addPatientFormFieldsGroup {
    padding: 0 50px;
}

.addPatientFormField {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    width: 330px;
}

.inputField, .inputField:focus {
    background-color: var(--grey-1);
    border: 1px solid var(--grey-5);
    caret-color: var(--grey-5);
    color: var(--grey-6);
    font-size: 16px;
    margin: 10px 0;
    width: 200px;
}

.inputFieldLabel {
    color: var(--grey-5);
    font-size: 16px;
    margin: 10px 0;
    min-width: 130px;
}

.inputFieldError {
    font-size: 12px;
    width: 100%;
    margin-left: 130px;
}

.inputFieldWrapper {
    margin: 0;
}