.patientCardForm {
  &Container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 10px;
  }

  &Fields {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 30px 80px;

    &Group {
      padding: 0 0px;
      margin: 1% 7%;
    }
  }

  &Field {
    display: flex;
    justify-content: space-between;
  }
}

.inputField,
.inputField:focus {
  background-color: var(--grey-1);
  border-bottom: 1px solid var(--grey-4);
}

.greyText {
  color: var(--grey-4);
  font-size: large;
}

.bigButton {
  background-color: var(--grey-2);
  border: 1px solid var(--grey-5);
  font-size: large;
}

.textSize {
  font-size: large;
}
.bigTextSize {
  font-size: x-large;
}
