.right {
  display: flex;
  justify-content: space-between;
  margin-left: auto;
}

.right button {
  background-color: transparent;
  border: 0;
}


.header {
  top: 0;
  display: flex;
  align-items: center;
  background: var(--grey-2);
  padding: 10px;
}

.header a{
  padding: 4px 8px;
}

.tabActive{
  background: var(--grey-3);

}